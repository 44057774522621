import React from 'react'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import FullpageVerticalCenter from '../../../components/FullpageVerticalCenter'
import BaronFooter from '../../../containers/BaronFooter'

const BaronCohen = () => {
  return (
    <FullpageVerticalCenter bg="white">
      <Container maxWidth="32em"  pt="5em" px="2em">
        <Text>英國劍橋大學的自閉症研究中心，針對正常智商的成年人，使用三種商數量表作為篩檢工具，協助診斷亞斯伯格症或高功能自閉症。接下來你將進行的測驗，是其中由Baron-Cohen S., Wheelwright S.及其他同事於2001年所提出的自閉類群商數（autism spectrum quotient）。</Text>
        <Text color="red" my="0.5em">此量表是三項篩檢工具的其中之一，結果無法代表最終診斷，若有任何疑問應直接尋求專家諮詢。</Text>
        <Box textAlign="center" my="1.25em">
          <Text fontSize={'1.25em'}>共50題，不用花很多時間在問題上，但請您快速並誠實的回答。</Text>
          <Button.Black mt="2em" to="/q/baron-cohen/1">開始測驗</Button.Black>
        </Box>
        <BaronFooter />
      </Container>
    </FullpageVerticalCenter>
  )
}

export default BaronCohen
